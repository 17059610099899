<template>
	<div class="news">
		<w-list class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<div class="middle" v-show="newsList.length > 0">
				<!-- <NewsList :news-list="newsList" :propsShow="true"></NewsList> -->
				<div class="middle-list">
					<div class="middle-list-item" v-for="item in newsList" @click="$router.push({ name: 'NewsFile',params:{id:item.id}})">
						<div class="news-item-title">{{ item.title }}</div>
						<div class="item-box">
							<div class="item-box-left">
								<div class="box-left-picture">
									<img src="@/assets/img/icon210.png" alt="" />
								</div>
								<div class="box-left-title" v-if="districtType() == 1">
									{{ item.district_name + "平安义警协会" }}</div>
								<div class="box-left-title" v-else>{{ item.district_name + "平安义警分会" }}</div>
							</div>
							<div class="item-box-right">
								<div class="box-right-picture">
									<template v-if="districtType() == 1">
										<img src="@/assets/img/icon_views_city.png" />
									</template>
									<template v-else>
										<img src="@/assets/img/icon_views_district.png" />
									</template>
								</div>
								<div class="box-right-title">{{ item.views }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</w-list>
	</div>
</template>

<script>
	import NewsApi from '@/api/news';
	import NewsList from '@/components/NewsList';
	import VantVendor from '@/vendor/vant';
	import WList from '@/components/List';
	import keepMiXin from '@/mixins/keep';
	import scrollMiXin from '@/mixins/scroll';
	import UtilsHelper from '@/vendor/utils';

	const keep = keepMiXin(['NewsDetail']);
	const scroll = scrollMiXin(['.list-view']);

	export default {
		mixins: [scroll, keep],
		inject: ['className', 'districtType', 'themeColor'],
		data() {
			return {
				newsList: [],
				loading: true,
				finished: false,
				isEmpty: false,
				paramsPage: 1,
				paramsLimit: 10,
				columnId: UtilsHelper.getCurrentDistrictConfig().column.talent
			};
		},
		created() {
			this.onLoad();
		},
		methods: {
			onLoad() {
				this.loading = true;

				let params = {
					page: this.paramsPage,
					limit: this.paramsLimit,
					column_id: this.columnId
				};

				NewsApi.articleList(params)
					.then(result => {
						this.paramsPage++;
						this.newsList = this.newsList.concat(result.data.list);

						this.loading = false;

						if (this.newsList.length <= 0) {
							this.isEmpty = true;
						}

						if (this.newsList.length >= result.data.total) {
							this.finished = true;
						}
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			}
		},
		components: {
			WList,
			NewsList
		}
	};
</script>

<style lang="less" scoped>
	.news {
		.list-view {
			height: 100vh;
		}

		.head-nav {
			background-color: #fff;

			.head-nav-top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 18px 20px 0;
				border-bottom: 1px solid #ddd;

				.color {
					font-weight: bold;
				}
			}

			div {
				font-size: 16px;
				line-height: 23px;
				color: #000;
				padding-bottom: 12px;
				margin-bottom: -1px;
			}
		}

		.head-nav-top.head-nav-top-city .color {
			color: #3377ff;
			border-bottom: 2px solid #3377ff;
		}

		.head-nav-top.head-nav-top-district .color {
			color: #ff6969;
			border-bottom: 2px solid #ff6969;
		}

		.middle {
			box-shadow: 0px 0px 0.08rem rgba(51, 119, 255, 0.1);

			.middle-list {
				padding: 10px;
				border-radius: 8px;
				background-color: #fff;
				box-sizing: border-box;

				.middle-list-item {
					margin-bottom: 15px;

					&.middle-list-item:last-child {
						margin-bottom: 0;
					}

					.news-item-title {
						font-size: 18px;
						// height: 46px;
						// min-height: 46px;
						// max-height: 46px;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}

					.item-box {
						margin-top: 16px;
						display: flex;
						justify-content: space-between;
						align-items: center;

						.item-box-left {
							display: flex;
							justify-content: flex-start;
							align-items: center;

							.box-left-picture {
								width: 12px;
								min-width: 12px;
								height: 12px;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}

							.box-left-title {
								margin-left: 5px;
								font-size: 12px;
								color: #666;
							}
						}

						.item-box-right {
							display: flex;
							align-items: center;

							.box-right-picture {
								width: 12px;
								min-width: 12px;
								height: 12px;
								margin-top: -2px;
								
								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}

							.box-right-title {
								margin-left: 5px;
								font-size: 12px;
								color: #666;
							}
						}
					}
				}
			}
		}
	}
</style>