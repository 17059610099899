<template>
	<div class="about-leader">
		<template v-for="item in Leader">
			<div :class="['head-top',{'head-conner':item.groups.length > 1}]">
				<div class="item" v-for="vo in item.groups">
					<div class="item-picture"><img :src="vo.avatar" alt="" /></div>
					<div class="item-title">{{ vo.name }}</div>
					<div class="item-text" v-if="vo.group_id == 13">职务：党建工作指导员</div>
					<div class="item-text" v-else>职务：{{ item.title }}</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import NewsApi from '@/api/news';

export default {
	name: 'AboutLeader',
	data() {
		return {
			Leader: "",
		};
	},
	created() {
		NewsApi.getLeaderList()
			.then(result => {
				this.Leader = result.data
				let list = result.data.slice()
				let index = this.Leader.findIndex(item=>item.id == 13)
				if(this.Leader.findIndex(item=>item.id == 13) && this.Leader[index].groups != ""){
					let listIndex = this.Leader.findIndex(item=>item.id == 2)
					list.splice(index,1)
					list[listIndex].groups.unshift(this.Leader[index].groups[0])
					this.Leader = list
				}
			})
			.catch(error => {
				console.log(error);
			});
	}
};
</script>

<style lang="less" scoped>
.about-leader {
	
	.item {
		width: 111px;
		// height: 196px;
		background: #f2f2f2;
		text-align: center;
		margin-bottom: 20px;
		
		.item-picture {
			width: 111px;
			min-width: 111px;
			height: 140px;
			
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		
		.item-title {
			font-weight: bold;
			font-size: 18px;
			line-height: 26px;
			color: #333;
			margin-top: 5px;
		}
		
		.item-text {
			font-size: 14px;
			line-height: 20px;
			color: #666;
			margin-bottom: 5px;
		}
	}
	
	.head-top {
		display: flex;
		justify-content: center;
	}
	
	.head-middle {
		display: flex;
		justify-content: center;
		margin-right: -10px;
		
		.item {
			margin-right: 10px;
		}
	}
	
	.head-conner {
		display: flex;
		flex-wrap: wrap;
		margin-right: -10px;
		
		.item {
			margin-right: 10px;
		}
	}
}
</style>
