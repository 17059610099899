<template>
	<div class="about-about" v-html="content"></div>
</template>

<script>
import NewsApi from '@/api/news'
import UtilsHelper from '@/vendor/utils';

export default {
	name: 'AboutAbout',
	data() {
		return {
			content: '',
			articleId: UtilsHelper.getCurrentDistrictConfig().column.about
		};
	},
	created() {
		let params = {
			article_id: this.articleId
		}
		
		NewsApi.articleDetail(params)
			.then(result => {
				this.content = result.data.content;
			})
			.catch(error => {
				console.log(error);
			})
	}
};
</script>

<style lang="less" scoped>
.about-about {
	font-size: 16px;
	line-height: 28px;
	color: #333;
	box-sizing: border-box;

}
</style>
